import React, { useRef } from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Button, Typography } from "@mui/material";

import whatapp from "../../assets/whatapp.svg";
const useStyles = makeStyles((theme) => ({
  title: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "26px !important",
    },
  },
  subtitle: {
    padding: "4%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px !important",
      textAlign: "center",
    },
  },
  mainDiv: {
    display: "flex",
    justifyContent: "space-evenly",
    flexDirection: "column !important",
    alignItems: "center",
    padding: "6% 12%",
    position: "relative",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: { padding: "17% 1%" },
  },
  sdiv: {
    backgroundColor: "#C01718",
    color: "#FFFFFF",
    position: "relative",
  },
  btn: {
    fontFamily: " Almarai-Bold !important",
    height: "56px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "5% !important",
      fontSize: "18px !important",
    },
  },
  whatapp: {
    position: "absolute",
    bottom: "12px",
    right: "40px",
    [theme.breakpoints.down("sm")]: {
      right: "15px",
    },
  },
  whatappicon: {
    [theme.breakpoints.down("sm")]: {
      width: "45px",
    },
  },
}));

function ContactUs(props) {
  const classes = useStyles();
  return (
    <>
      <Grid
        container
        justifyContent="center"
        style={{ padding: "0% 8%" }}
        id="contact"
        className={classes.sdiv}
      >
        <Grid item xs={12} sm={12} md={12} className={classes.mainDiv}>
          <Typography
            variant="h2"
            className={classes.title}
            style={{ fontFamily: "Almarai-SemiBold" }}
          >
            {"Contact Us"}
          </Typography>
          <Typography variant="h4" className={classes.subtitle}>
            {
              "Our legal experts are friendly, professional and approachable. Call us now or send a quick WhatsApp to get started."
            }
          </Typography>

          <Button
            variant="contained"
            color="warning"
            className={classes.btn}
            target="_blank"
            href="https://api.whatsapp.com/send?phone=971501300154"
          >
            {"Start the conversation"}
          </Button>
        </Grid>{" "}
        <section className={classes.whatapp}>
          <a
            target="_blank"
            href="https://api.whatsapp.com/send?phone=971501300154"
          >
            <img src={whatapp} className={classes.whatappicon} />
          </a>
        </section>
      </Grid>

    </>
  );
}
export default ContactUs;
