import React from "react";
import { render } from "react-dom";
import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";
import "./slider-animations.css";
import "./styles.css";
import { Button } from "@mui/material";
import whatapp from "../../assets/whatapp.svg";

const content = [
  {
    title: "Modern businesses need modern solutions",
    description:
      "Our remote services and flexible approach tailors to your needs and workflows",

    image: "https://storage.googleapis.com/ishro/uploads/62f64bfa09580.svg",
    user: "Luan Gjokaj",
    userProfile: "https://i.imgur.com/JSW6mEk.png",
  },
  {
    title: "Start moving forward. Let’s resolve your legal issues",
    description:
      "Our remote services and flexible approach tailors to your needs and workflows",

    image: "https://storage.googleapis.com/ishro/uploads/62f64c1bec95b.svg",
    user: "Erich Behrens",
    userProfile: "https://i.imgur.com/0Clfnu7.png",
  },
  {
    title: "Notarize online. Anytime. Anywhere",
    description:
      "Documents notarized at your convenience, quickly, securely using online communications and electronic signatures. ",

    image: "https://storage.googleapis.com/ishro/uploads/62f64c3f4c954.svg",
    user: "Bruno Vizovskyy",
    userProfile: "https://i.imgur.com/4KeKvtH.png",
  },
  {
    title: "Notary made simple",
    description:
      "Simplifying complex paperwork so you can focus on growing your business",

    image: "https://storage.googleapis.com/ishro/uploads/62f64c6b27578.svg",
    user: "Luan Gjokaj",
    userProfile: "https://i.imgur.com/JSW6mEk.png",
  },
  {
    title: "Secure. Legal. Fast.",
    description:
      "Streamlined electronic transactions to get your documents notarized correctly and on time",

    image: "https://storage.googleapis.com/ishro/uploads/62f64c8cc2e15.svg",
    user: "Erich Behrens",
    userProfile: "https://i.imgur.com/0Clfnu7.png",
  },
  {
    title: "We are The Notary that comes to you",
    description:
      "We understand you’re busy and will visit you anywhere to get your documents notarized as efficiently as possible",

    image: "https://storage.googleapis.com/ishro/uploads/62f64ca7dd173.svg",
    user: "Bruno Vizovskyy",
    userProfile: "https://i.imgur.com/4KeKvtH.png",
  },
];

export default function Carousel() {
  return (
    <div id="home">
      <Slider className="slider-wrapper" autoplay={2000}>
        {content.map((item, index) => (
          <div
            key={index}
            className="slider-content"
            style={{
              background: `url('${item.image}') no-repeat center center`,
            }}
          >
            <div className="inner">
              <h1>{item.title}</h1>
              <p>{item.description}</p>
              <Button
                variant="contained"
                
                sx={{ padding: { xs: "4px 26px", lg: "4px 43px" } }}target="_blank"
                href="https://api.whatsapp.com/send?phone=971501300154"
              >
                {"Consult"}
              </Button>
            </div>
            <section>
              <a
                target="_blank"
                href="https://api.whatsapp.com/send?phone=971501300154"
              >
                <img src={whatapp} />
              </a>
            </section>
          </div>
        ))}
      </Slider>
    </div>
  );
}
