import React, { useState } from "react";
import { Outlet } from "react-router-dom";

import Footer from "../footer/footer";
import Header from "../header/header";
const DefaultLayout = () => {
  return (
    <div>
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
};

export default DefaultLayout;
