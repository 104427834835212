import React from "react";
import banner1 from "../../../assets/About Notary.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { makeStyles } from "@mui/styles";
import { Grid, IconButton, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  closePaper: {
    borderRadius: "18px",
    padding: "20px",
  },
  title: {
    [theme.breakpoints.down("sm")]: { fontSize: "42px !important" },
  },
  qtitle: {
    fontFamily: "Almarai-SemiBold!important",
    fontSize: "26px !important",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: { fontSize: "20px !important" },
  },
  subtitle1: {
    fontSize: "26px!important",
    fontFamily: "Almarai-SemiBold!important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px!important",
      marginBottom: "3%!important",
    },
  },
  subtitle2: {
    fontSize: "32px!important",
    fontFamily: "Almarai-Bold!important",
    padding: "2% 0%",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "3%!important",
      fontSize: "18px!important",
    },
  },
  subtitle3: {
    fontSize: "20px !important",
    fontFamily: "Almarai-regular!important",
    marginBottom: "2%!important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px !important",
      textAlign: "center",
    },
  },

  mainDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column!important",
    padding: "4% 22%",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: { padding: "10%", alignItems: "center" },
  },
  div1: {
    whiteSpace: "nowrap",
    paddingTop: "2%",
    [theme.breakpoints.down("sm")]: { padding: "10% 0%" },
  },
  borderDiv: {
    padding: "4% 24%",
  },
  border: {
    border: "2px solid #C01718",
    [theme.breakpoints.down("sm")]: {
      border: "2px solid #C01718",
    },
  },
  arrowBtn: {
    position: "absolute!important",
    color: "white!important",
    top: "15%",
    left: "3%",
    fontSize: "16px!important",
    [theme.breakpoints.down("sm")]: {
      left: "0%",
      top: "7%!important",
    },
  },
}));
export default function AboutUs() {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <div>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12}>
          {" "}
          <IconButton href="/" className={classes.arrowBtn}>
            <ArrowBackIcon /> {!isMobile && "Back"}
          </IconButton>
          <img src={banner1} alt="" style={{ width: "100%" }} />
        </Grid>

        <Grid item xs={12} md={12} sm={12} className={classes.mainDiv}>
          <Typography variant="h3" className={classes.subtitle1}>
            {
              "Welcome to the leading Notary Public firm in the UAE. The Notary is licensed by Dubai Courts."
            }
            <div className={classes.borderDiv}>
              <p className={classes.border}></p>
            </div>
          </Typography>

          <Typography className={classes.subtitle3}>
            Modern times need modern notary services. Our notarization,
            translation, and legalization services are designed to be flexible
            to fit your schedule and budget. With our streamlined online process
            specializing in electronic transactions and attestations, you can
            get your documents attested in a matter of minutes/hours.
          </Typography>

          <Typography className={classes.subtitle3}>
            Our job is to act as the bridge between complex requirements of the
            UAE legal system so that your documents are ready to use when you
            need them. No fuss. No delays.
          </Typography>

          <Typography className={classes.subtitle3}>
            Notarize documents in the UAE fast. If you’re setting up a company
            in the UAE while abroad and want to grant personal or corporate
            power of attorney, need corporate or personal certificate
            attestation or need to authenticate corporate documents, our quick
            and efficient notary public can help.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
