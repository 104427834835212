import React from "react";
import backgroud from "../../assets/about_us/Black BG.svg";
import div1 from "../../assets/div1.svg";
import div2 from "../../assets/div2.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles } from "@mui/styles";
import { Grid, Button, Typography } from "@mui/material";
import icon1 from "../../assets/icon1.svg";
import icon2 from "../../assets/icon2.svg";
const useStyles = makeStyles((theme) => ({
  closePaper: {
    borderRadius: "18px",
    padding: "20px",
  },
  title: {
    [theme.breakpoints.down("sm")]: { fontSize: "42px !important" },
  },
  qtitle: {
    fontFamily: "Almarai-SemiBold!important",
    fontSize: "26px !important",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: { fontSize: "20px !important" },
  },
  subtitle1: {
    fontSize: "46px!important",
    position: "relative",
    color: "#E5E5E5",
    fontFamily: "Almarai-SemiBold!important",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "8%!important",
      fontSize: "26px!important",
    },
  },
  subtitle2: {
    fontSize: "26px!important",
    fontFamily: "Almarai-Bold!important",
    padding: "4% 0%",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px!important",
    },
  },
  subtitle3: {
    fontSize: "20px !important",
    fontFamily: "Almarai-regular!important",
    height: "20vh",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px !important",
      height: "12vh",
      textAlign: "center",
    },
  },
  mainDiv2: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column!important",
    paddingBottom: "8%",
    [theme.breakpoints.down("sm")]: { alignItems: "center" },
  },
  mainDiv: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "flex-start",
    flexDirection: "column!important",
    padding: "0% 5%",
    [theme.breakpoints.down("sm")]: { padding: "5% 1%", alignItems: "center" },
  },
  div1: {
    whiteSpace: "nowrap",
    paddingTop: "2%",
    [theme.breakpoints.down("sm")]: { padding: "10% 0%" },
  },

  mainDiv1: {
    padding: "6% 24%",
    [theme.breakpoints.down("sm")]: { padding: "16% 5%" },
  },
  btn: {
    width: "200px!important",
    height: "56px!important",
    borderRadius: "40px!important",
    fontSize: "14px!important",
    fontFamily: "Almarai-Bold!important",
    [theme.breakpoints.down("sm")]: {
      width: "136px!important",
      height: "50px!important",
    },
  },
  btn1: {
    width: "200px!important",
    height: "56px!important",
    borderRadius: "40px!important",
    fontSize: "14px!important",
    fontFamily: "Almarai-Bold!important",
    color: "#6F6C90 !important",
    borderColor: "#6F6C90 !important",
    [theme.breakpoints.down("sm")]: {
      width: "136px!important",
      height: "50px!important",
    },
  },
  closebtn: {
    width: "32px",
    height: "32px",
    marginLeft: "91%  !important",
    position: "absolute  !important",
    zIndex: "1",
    right: "16px",
    top: "16px",
    backgroundColor: "#ffbb00!important",
    [theme.breakpoints.down("sm")]: {
      right: "30px",
      top: "30px",
    },
  },
  border: {
    border: "4px solid #C01718",
    [theme.breakpoints.down("sm")]: {
      border: "4px solid #C01718",
    },
  },
  borderDiv: {
    padding: "0% 31% 4%",
    [theme.breakpoints.down("sm")]: {
      padding: "0% 31% 4%",
    },
  },
  sub: {
    padding: "15%",
    textAlign: "center",
    color: "#E5E5E5",
    backgroundRepeat: "no-repeat !important",
    backgroundSize: "cover !important",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "5%",
    },
  },
  btn: {
    backgroundColor: "#E5E5E5 !important",
    color: "#1D1D1B !important",
    padding: "1.5% 6% !important",
    fontSize: "16px !important",
    fontFamily: "Almarai-SemiBold !important",
  },
}));
export default function WhatWe() {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <div style={{ background: `url('${backgroud}') ` }} id="service">
      <Grid container justifyContent="center" className={classes.mainDiv1}>
        <Grid item xs={12} sm={12} md={12} className={classes.mainDiv2}>
          <Typography variant="h3" className={classes.subtitle1}>
            {"What we do"}
            <div className={classes.borderDiv}>
              <p className={classes.border}></p>
            </div>
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={6} className={classes.mainDiv}>
          <div
            className={classes.sub}
            style={{ background: `url('${div1}') ` }}
          >
            <img src={icon1} />
            <Typography className={classes.subtitle2}>
              {"Notary Public Services"}
            </Typography>

            <Typography className={classes.subtitle3}>
              Our expert’s advice, draft and attest documents and paperwork
              relating to a variety of business activities...
            </Typography>
            <Button className={classes.btn} href="/notaryPublic">
              Read More
            </Button>
          </div>
        </Grid>
        <Grid item xs={12} md={6} sm={12} className={classes.mainDiv}>
          <div
            className={classes.sub}
            style={{ background: `url('${div2}') ` }}
          >
            <img src={icon2} />
            <Typography className={classes.subtitle2}>
              {"Administrative Services"}
            </Typography>

            <Typography className={classes.subtitle3}>
              From efficient translations and drafting paperwork using effective
              legal language to serving legal notices and comprehensive...
            </Typography>
            <Button className={classes.btn} href="/adminServices">
              Read More
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
