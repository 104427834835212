import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Button,
  IconButton,
  ListItemButton,
  Tabs,
  Tab,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { styled, useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import CloseIcon from "@mui/icons-material/CloseRounded";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import { scroller } from "react-scroll";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import Logo from "../../assets/logo.svg";

import { useNavigate } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  txtdiv: {
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: { width: "119px" },
  },
  header: {
    padding: "0.85% 1.5%",
    position: "absolute",
    top: "0",
    zIndex: "1",
    backgroundColor: "rgba(0, 0, 0, 0.55)",
    color: "#E5E5E5",
    position: "fixed",
    [theme.breakpoints.down("sm")]: { padding: "1% 6%" },
  },
  div1: {
    paddingLeft: "12%",
    [theme.breakpoints.down("sm")]: { paddingLeft: "0%" },
  },
  div2: {
    justifyContent: "space-evenly",
    padding: "0% 9%",
    [theme.breakpoints.down("xl")]: { padding: "0% 4%" },
    [theme.breakpoints.down("lg")]: { padding: "0% 1%" },
  },
  btn: {
    fontSize: "14px!important",
    textTransform: "uppercase!important",
    color: "#E5E5E5 !important",
  },
  btn1: {
    height: "28px",
    width: "104px",
    margin: "0%!important",
  },
  btn2: {
    fontFamily: "Almarai-Bold!important",
    width: "122px !important",
    height: "44px !important",
    fontSize: "14px !important",
    whiteSpace: "nowrap !important",
    color: "#FFFFFF!important",
    border: "1px solid white!important",
    margin: "6px 17px!important",
  },
  btn3: {
    fontFamily: "Almarai-Bold!important",
    width: "122px !important",
    height: "44px !important",
    fontSize: "14px !important",
    whiteSpace: "nowrap !important",
    color: "#FFFFFF!important",
    margin: "6px 0px!important",
    marginLeft: "20px !important",
  },
  btn4: {
    borderRadius: "90px !important",
    fontFamily: "Almarai-SemiBold !important",
    color: "#f5f5f5 !important",
    fontSize: "16px !important",
  },
  arrowimg: {
    marginLeft: "-323px",
    marginTop: "16px",
    [theme.breakpoints.down("sm")]: {
      transform: "rotate(48deg)",
      marginLeft: "-102px",
      marginTop: "-17px",
    },
  },

  subtitle3: {
    fontFamily: "Almarai-SemiBold!important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px!important",
    },
  },
  subtitle4: {
    fontFamily: " Almarai-light !important",
  },
  mainDiv: {
    backgroundColor: "#EAFFEA !important",
    display: "flex",
    flexDirection: "column!important",
    justifyContent: "center",
    alignItems: "center",
    padding: "7% 2%",
  },
  closebtn: {
    fontFamily: "Almarai-SemiBold",
    fontSize: "16px!important",
    borderRadius: "40px!important",
    position: "absolute!important",
    bottom: "2.7%!important",
    width: "15%!important",
    margin: "0% 0.8%!important",
    [theme.breakpoints.down("lg")]: { bottom: "2.8%!important" },
    [theme.breakpoints.down("xl")]: {
      bottom: "3.5%!important",
      height: "44px",
      margin: "0% 0.8%!important",
    },
  },
  groupbtn: {
    float: "right",
    bottom: "2%",
    position: "absolute",
    right: "2%",
    [theme.breakpoints.down("lg")]: { bottom: "2%!important" },
    [theme.breakpoints.down("xl")]: { bottom: "2.8%!important" },
  },
  menu: {
    fontFamily: "Almarai-SemiBold!important",
    margin: "14% 8%!important",
    [theme.breakpoints.down("xl")]: { margin: "9% 7%!important" },
  },
  dashbody: {
    padding: "3% 2%",
    backgroundColor: "#FFFFFF",
    height: "91vh",
    [theme.breakpoints.down("xl")]: { padding: "1.8% 2%!important" },
  },
  imgDiv: {
    width: "20%",
    [theme.breakpoints.down("xl")]: { width: "26%" },
  },
}));

const drawerWidth = 240;

const Header = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState("home");

  const navigate = useNavigate();
  const handleChange = (event, key) => {
    navigate("/");
    setTimeout(function () {
      scroller.scrollTo(key, {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    }, 1000);
    setValue(key);
  };
  const handleDrawerClose = ( key) => {
    
    navigate("/");
    setTimeout(function () {
      scroller.scrollTo(key, {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    }, 1000);

    setOpen(false);
  };
  const handleDrawerOpen = (e, key) => {
    setOpen(true);
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      className={classes.header}
    >
      <Grid item xs={9} md={6} className={classes.div1}>
        <img src={Logo} alt="The Notary Logo" className={classes.imgDiv} />
      </Grid>
      <Grid
        item
        xs={6}
        md={6}
        className={classes.div2}
        sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab value="home" label="HOME" className={classes.btn} />
          <Tab value="about" label="About" className={classes.btn} />
          <Tab value="service" label="Services" className={classes.btn} />
          <Tab value="how" label="How it Works" className={classes.btn} />
          <Tab value="contact" label="Contact" className={classes.btn} />
        </Tabs>
      </Grid>
      <Grid
        item
        xs={3}
        md={2}
        sx={{
          display: { xs: "flex", sm: "flex", md: "none" },
          justifyContent: "flex-end",
        }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="end"
          onClick={handleDrawerOpen}
          sx={{ ...(open && { display: "none" }) }}
        >
          <MenuIcon />
        </IconButton>
      </Grid>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader style={{ justifyContent: "flex-end" }}>
          <IconButton onClick={handleDrawerClose}>
            <CloseIcon style={{ color: "white" }} />
          </IconButton>
        </DrawerHeader>
        <List>
          {[
            { key: "home", text: "Home" },
            { key: "about", text: "About" },
            { key: "service", text: "Service" },
            { key: "how", text: "How it Works" },
            { key: "contact", text: "Contact" },
          ].map((data, index) => (
            <ListItem disablePadding key={index}>
              <ListItemButton onClick={() => handleDrawerClose(data.key)}>
                <ListItemText primary={data.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <div style={{ bottom: "0%", position: "absolute", left: "16%" }}>
          <IconButton target="_blank" href="https://www.facebook.com/The-Notary-110606385101238">
            <FacebookIcon sx={{ color: "white" }} />
          </IconButton>{" "}
          <IconButton target="_blank" href="https://www.instagram.com/thenotaryae">
            <InstagramIcon sx={{ color: "white" }} />
          </IconButton>
          {/* <IconButton>
            <TwitterIcon sx={{ color: "white" }} />
          </IconButton>
          <IconButton>
            <YouTubeIcon sx={{ color: "white" }} />
          </IconButton> */}
        </div>
      </Drawer>
    </Grid>
  );
};
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));
export default Header;
