import React, { useEffect } from "react";
import "../src/assets/css/app.css";
import Routes from "./routes";
import GlobalStyles from "../src/assets/styles/globalStyles";
import Jss from "./Jss";
import { Helmet, HelmetProvider } from "react-helmet-async";

const App = ({}) => {
  return (
    <HelmetProvider>
      <Jss>
        <Helmet>
          <title>The Notary</title>
          <meta
            name="description"
            content="Modern times need modern notary services. Our notarization,
          translation, and legalization services are designed to be flexible
          to fit your schedule and budget. With our streamlined online process
          specializing in electronic transactions and attestations, you can
          get your documents attested in a matter of minutes/hours."
          />
        </Helmet>
        <GlobalStyles />

        <Routes />
      </Jss>
    </HelmetProvider>
  );
};

export default App;
