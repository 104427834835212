import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Link, Typography, IconButton } from "@mui/material";
import { scroller } from "react-scroll";
import Logo from "../../assets/logo.svg";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
const useStyles = makeStyles((theme) => ({
  imgDiv: {
    width: "20%",
    paddingBottom: "2%",
    [theme.breakpoints.down("xl")]: { width: "40%" },
  },
  mainDiv: {
    backgroundColor: "#292929",
    padding: "2% 10% 1% 10%",
    color: "white",
    [theme.breakpoints.down("sm")]: { textAlign: "center" },
  },
  mainDiv2: {
    backgroundColor: "#292929",
    padding: "1% 10% 2% 10%",
  },
  title: {
    fontSize: "16px !important",
    fontFamily: "Almarai-SemiBold !important",
  },
  subtitle: {
    fontSize: "16px !important",
    color: "rgba(255, 255, 255, 0.6)!important",
    paddingTop: "4%",
  },
  subtitle5: {
    fontSize: "16px !important",
    color: " rgba(255, 255, 255, 0.6)!important",
    [theme.breakpoints.down("sm")]: { textAlign: "center" },
  },
  subtitle3: {
    fontSize: "16px !important",
    color: "rgba(255, 255, 255, 0.6)!important",
    borderRight: "1px solid #A9A9A9",
    paddingRight: "2%",
  },
  subtitle2: {
    paddingTop: "1%",
    fontSize: "14px !important",
  },
  subtitle4: {
    padding: "2% 0%",
  },
  txt: {
    paddingLeft: "2%",
    fontSize: "14px",
  },
  mobileDiv: {
    [theme.breakpoints.down("sm")]: { textAlign: "center", padding: "5% 0%" },
  },
}));

function Footer() {
  const classes = useStyles();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:600px)");
  const handleScroll = (key) => {
    navigate("/");
    setTimeout(function () {
      scroller.scrollTo(key, {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    }, 1000);
   
  };
  return (
    <footer>
      <Grid container className={classes.mainDiv}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <img src={Logo} alt="The Notary Logo" className={classes.imgDiv} />
          {!isMobile && (
            <>
              {" "}
              <Typography className={classes.subtitle2}>
                Dragon Mart​1,​1st Floor (Next to RAK Bank )
              </Typography>
              <Typography className={classes.subtitle2}>
                08:00 am - 05:00 pm
              </Typography>
              <Typography className={classes.subtitle4}>
                <span className={classes.subtitle3}> Contact </span>{" "}
                <span className={classes.txt}>+971 501300154 </span>{" "}
                <span className={classes.txt}> hello@thenotary.ae</span>
              </Typography>
            </>
          )}
        </Grid>

        <Grid lg={3} md={3} sm={6} xs={12} className={classes.mobileDiv}>
          <Typography className={classes.title}>Company</Typography>
          <Typography
            className={classes.subtitle}
            onClick={() => handleScroll("about")}
          >
            About us
          </Typography>
          <Typography
            className={classes.subtitle}
            onClick={() => handleScroll("service")}
          >
            Service
          </Typography>
          <Typography
            className={classes.subtitle}
            onClick={() => handleScroll("how")}
          >
            How it Works
          </Typography>
        </Grid>
        <Grid lg={3} md={3} sm={6} xs={12} className={classes.mobileDiv}>
          <Typography className={classes.title}>Services</Typography>
          <Typography className={classes.subtitle}>
            {" "}
            <Link
              underline="none"
              href="/notaryPublic"
              sx={{ color: "rgba(255, 255, 255, 0.6)!important" }}
            >
              Notary Public Services
            </Link>
          </Typography>
          <Typography className={classes.subtitle}>
            {" "}
            <Link
              underline="none"
              href="/adminServices"
              sx={{ color: "rgba(255, 255, 255, 0.6)!important" }}
            >
              Administrative Services
            </Link>{" "}
          </Typography>
        </Grid>

        {isMobile && (
          <Grid
            item
            lg={3}
            md={3}
            sm={12}
            xs={12}
            style={{ textAlign: "center" }}
          >
            {" "}
            <Typography className={classes.subtitle2}>
              Dragon Mart​1,​1st Floor (Next to RAK Bank )
            </Typography>
            <Typography className={classes.subtitle2}>
              08:00 am - 05:00 pm
            </Typography>
            <Typography
              sx={{
                fontSize: "16px !important",
                color: "rgba(255, 255, 255, 0.6)!important",
                padding: "5% 0%",
              }}
            >
              Contact
            </Typography>
            <Typography className={classes.txt}>+971 501300154</Typography>
            <Typography className={classes.txt} sx={{paddingBottom:'5%'}}>hello@thenotary.ae</Typography>
            <IconButton  target="_blank" href="https://www.facebook.com/The-Notary-110606385101238">
              <FacebookIcon sx={{ color: "white" }} />
            </IconButton>{" "}
            <IconButton target="_blank" href="https://www.instagram.com/thenotaryae">
              <InstagramIcon sx={{ color: "white" }} />
            </IconButton>
            {/* <IconButton>
              <TwitterIcon sx={{ color: "white" }} />
            </IconButton>
            <IconButton>
              <YouTubeIcon sx={{ color: "white" }} />
            </IconButton> */}
          </Grid>
        )}
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{ borderBottom: "0.5px solid rgba(255, 255, 255, 0.6)" }}
      ></Grid>
      <Grid container className={classes.mainDiv2}>
        <Grid item lg={9} md={9} sm={12} xs={12}>
          {" "}
          <Typography className={classes.subtitle5}>
            {" "}
            The Notary 2022 All Right Reserved{" "}
          </Typography>{" "}
        </Grid>
        {!isMobile && (
          <Grid item lg={3} md={3} sm={12} xs={12}>
            {" "}
            <IconButton target="_blank" href="https://www.facebook.com/The-Notary-110606385101238">
              <FacebookIcon sx={{ color: "white" }} />
            </IconButton>{" "}
            <IconButton target="_blank" href="https://www.instagram.com/thenotaryae">
              <InstagramIcon sx={{ color: "white" }} />
            </IconButton>
            {/* <IconButton>
              <TwitterIcon sx={{ color: "white" }} />
            </IconButton>
            <IconButton>
              <YouTubeIcon sx={{ color: "white" }} />
            </IconButton> */}
          </Grid>
        )}
      </Grid>
    </footer>
  );
}

export default Footer;
