import React from "react";
import { useRoutes } from "react-router-dom";
import DefaultLayout from "./components/pages/defaultLayout.jsx";
import Home from "./components/pages/home/home.jsx";
import NotaryPublic from "./components/pages/home/NotaryPublic.jsx";
import AdminServices from "./components/pages/home/AdminServices.jsx";
import AboutUs from "./components/pages/home/AboutUs.jsx";
const routesList = (isLoggedIn) => [
  {
    path: "/",
    element: <DefaultLayout />,
    children: [
     
      { path: "", element: <Home /> }, 
       { path: "aboutUs", element: <AboutUs /> },
       { path: "notaryPublic", element: <NotaryPublic /> },
        { path: "adminServices", element: <AdminServices /> },
    ],
  },
];

const Routes = () => {
  const routing = useRoutes(routesList(false));
  return routing;
};

export default Routes;
