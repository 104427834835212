import React, { useRef } from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography, Dialog } from "@mui/material";
import img1 from "../../assets/img1.svg";
import img2 from "../../assets/img2.svg";
import img3 from "../../assets/img3.svg";
import background from "../../assets/background.png";
const useStyles = makeStyles((theme) => ({
  closePaper: {
    borderRadius: "18px",
    padding: "20px",
  },
  title: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "26px !important",
      marginBottom: "8%!important",
    },
  },
  subtitle: {
    padding: "2%",
    fontSize: "30px!important",
    fontFamily: "Almarai-SemiBold !important",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "8%!important",
      textAlign: "center",
      fontSize: "18px!important",
    },
  },
  subtitle2: {
    fontSize: "22px!important",
    [theme.breakpoints.down("sm")]: { fontSize: "14px!important" },
  },
  mainDiv: {
    display: "flex",
    justifyContent: "space-evenly",
    flexDirection: "column !important",
    alignItems: "center",
    paddingBottom: "6%",
    position: "relative",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: { padding: "17% 1%" },
  },
  sdiv: {
    backgroundColor: "#F4F4F4",
    color: "#1D1D1B",
    padding: "6% 6%",
  },
  arrowimg: {
    marginLeft: "-323px",
    marginTop: "16px",
    [theme.breakpoints.down("sm")]: {
      transform: "rotate(48deg)",
      marginLeft: "-102px",
      marginTop: "-17px",
    },
  },
  btn: {
    fontFamily: " Almarai-Bold !important",
    height: "56px",
    [theme.breakpoints.down("sm")]: { marginTop: "5% !important" },
  },
  imgdata: {
    width: "100%",
  },
  card: {
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
    margin: "0% 11%",
    [theme.breakpoints.down("sm")]: {
      margin: "4% 2%",
    },
  },
  data: {
    padding: "6%",
    fontFamily: "Almarai-SemiBold !important",
    textAlign: "center",
    height: "88px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  border: {
    border: "4px solid #C01718",
    [theme.breakpoints.down("sm")]: {
      border: "4px solid #C01718",
    },
  },
  borderDiv: {
    padding: "0% 39% 4%",
    [theme.breakpoints.down("sm")]: {
      padding: "0% 39% 4%",
    },
  },
  backgroundDiv: {
    backgroundSize: "contain !important",
    backgroundRepeat: "no-repeat !important",
    padding: "8% 12%",
  },
}));

function HowItWorks(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [type, setType] = React.useState(false);
  const handleClickOpen = (val) => {
    setType(val);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className={classes.sdiv} id="how">
      <Grid
        container
        justifyContent="center"
        style={{ padding: "0% 8%" }}
        id="Home"
      >
        <Grid item xs={12} sm={12} md={12} className={classes.mainDiv}>
          <Typography
            variant="h1"
            className={classes.title}
            style={{ fontFamily: "Almarai-SemiBold", position: "relative" }}
          >
            {"How it works"}
            <div className={classes.borderDiv}>
              <p className={classes.border}></p>
            </div>
          </Typography>
          <Typography variant="h4" className={classes.subtitle}>
            {" We’re at your service 24/7 "}
          </Typography>
          <Typography variant="h4" className={classes.subtitle2}>
            {
              "We attest documents online using secure digital platforms. It doesn’t matter whether you’re in Dubai or outside the UAE, just reach out to one of our experts to get started.Get your documents notarized in the UAE in three quick steps."
            }
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        {" "}
        <Grid item xs={12} sm={12} md={4}>
          <div className={classes.card} onClick={() => handleClickOpen("book")}>
            <img src={img1} alt="The Notary Logo" className={classes.imgdata} />
            <Typography variant="h5" className={classes.data}>
              {"Book an in-person or remote consultation"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <div
            className={classes.card}
            onClick={() => handleClickOpen("identity")}
          >
            <img src={img2} alt="The Notary Logo" className={classes.imgdata} />
            <Typography variant="h5" className={classes.data}>
              {"Identity Verification"}
            </Typography>{" "}
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          {" "}
          <div className={classes.card} onClick={() => handleClickOpen("easy")}>
            <img src={img3} alt="The Notary Logo" className={classes.imgdata} />
            <Typography variant="h5" className={classes.data}>
              {"Easy online delivery"}
            </Typography>{" "}
          </div>
        </Grid>
      </Grid>{" "}
      <Dialog open={open} onClose={handleClose} maxWidth={"sm"}>
        <div
          style={{ background: `url('${background}') ` }}
          className={classes.backgroundDiv}
        >
          <Typography variant="h4" className={classes.subtitle}>
            {type == "book"
              ? "Book an in-person or remote consultation to prepare a draft"
              : type == "identity"
              ? "Identity Verification"
              : "Easy online delivery"}
            <div className={classes.borderDiv}>
              {" "}
              <p
                className={classes.border}
                style={{ marginTop: "2%" }}
              ></p>{" "}
            </div>
          </Typography>

          <Typography
            variant="h4"
            className={classes.subtitle2}
            style={{ paddingTop: "12%" }}
          >
            {type == "book"
              ? "We’ll discuss your notary needs over the phone or at your home/office and start preparing a draft of the required documents."
              : type == "identity"
              ? "Once the draft is done, make sure to keep it ready along with a valid Emirates ID and passport for verification. Our notaries will then video call to verify your identity."
              : "You’ll receive an electronic signature from Dubai Courts through an OTP via SMS. Once this is received, the final documents will be delivered to your email or WhatsApp."}
          </Typography>
        </div>
      </Dialog>
    </div>
  );
}
export default HowItWorks;
