import React from "react";
import banner1 from "../../../assets/Administrative Services.svg";
import subimg from "../../../assets/subimg.svg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles } from "@mui/styles";
import { Grid, Button, Typography, IconButton } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  subtitle1: {
    fontSize: "26px!important",
    fontFamily: "Almarai-Regular!important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px!important",
      marginBottom: "3%!important",
    },
  },
  subtitle2: {
    fontSize: "22px !important",
    fontFamily: "Almarai-regular!important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px !important",
      textAlign: "center",
    },
  },
  subtitle3: {
    fontSize: "18px !important",
    fontFamily: "Almarai-regular!important",
    marginLeft: "9%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px !important",
      textAlign: "center",
    },
  },

  mainDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column!important",
    padding: "3% 22%",
    [theme.breakpoints.down("sm")]: { padding: "10%", alignItems: "center" },
  },
  div1: {
    borderRight: "2px solid #C01718 !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    fontSize: "22px",
    color: "#C01718",
    fontFamily: "Almarai-SemiBold",
    paddingRight: "4%",
  },
  div2: {
    padding: "3%",
    [theme.breakpoints.down("sm")]: {
      padding: "0%",
    },
  },
  borderDiv: {
    padding: "4% 24%",
  },
  border: {
    border: "2px solid #C01718",
    [theme.breakpoints.down("sm")]: {
      border: "2px solid #C01718",
    },
  },
  detailDiv: {
    padding: "0% 22% 16% 22%",
    [theme.breakpoints.down("sm")]: {
      padding: "0% 10%",
    },
  },
  title: {
    fontSize: "32px!important",
    fontFamily: "Almarai-Bold!important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px!important",
      paddingBottom: "4%",
    },
  },
  mainDiv2: {
    padding: "0% 22%",
    [theme.breakpoints.down("sm")]: { padding: "0% 10%", alignItems: "center" },
  },
  mainDiv3: {
    paddingLeft: "6%!important",
    display: "flex",
    flexDirection: "column!important",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0%!important",
      textAlign: "center",
    },
  },
  num: {
    color: "rgba(41, 41, 41, 0.6)",
    fontSize: "18px",
    paddingRight: "3%",
  },
  titleDiv: {
    color: "#292929",
    fontSize: "18px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px!important",
      textAlign: "center",
    },
  },
  subDiv: {
    color: "rgba(41, 41, 41, 0.8)",
    fontSize: "18px",
    padding: "3% 0% 3%  5%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px!important",
      textAlign: "center",
    },
  },
  arrowBtn: {
    position: "absolute!important",
    color: "white!important",
    top: "15%",
    left: "3%",
    fontSize: "16px!important",
    [theme.breakpoints.down("sm")]: {
      left: "0%",
      top: "7%!important",
    },
  },
}));
export default function AdminServices() {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <div>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12}>
          <IconButton href="/" className={classes.arrowBtn}>
            <ArrowBackIcon />
            {!isMobile && "Back"}
          </IconButton>
          <img src={banner1} alt="" style={{ width: "100%" }} />
        </Grid>

        <Grid item xs={12} md={12} sm={12} className={classes.mainDiv}>
          <Typography variant="h3" className={classes.subtitle1}>
            {
              "From efficient translations and drafting paperwork using effective legal language to serving legal notices and comprehensive business setups, our advisors will help you get the job done quickly and efficiently."
            }
          </Typography>
        </Grid>
        <Grid container justifyContent="center" className={classes.mainDiv2}>
          {!isMobile && (
            <Grid item xs={1} md={6} sm={6}>
              <img src={subimg} alt="" style={{ width: "100%" }} />
            </Grid>
          )}
          <Grid item xs={12} md={6} sm={6} className={classes.mainDiv3}>
            <Typography variant="h3" className={classes.title}>
              {" Advisory Services"}
            </Typography>
            <Typography variant="h3" className={classes.subtitle2}>
              {
                "With our in-depth knowledge of UAE regulations our experts provide premium advice on how to navigate complex legal frameworks in the region"
              }
            </Typography>
            <Typography
              sx={{
                paddingTop: { xs: "5%" },
                fontSize: "22px",
                color: "#C01718",
                fontFamily: "Almarai-SemiBold",
              }}
            >
              {"We help with"}
            </Typography>

            <ul className={classes.subtitle3}>
              <li> Business setup for startups and entrepreneurs</li>
              <li>
                Company formation, mainland and freezone, in Dubai and across
                the UAE
              </li>
              <li> How to structure companies and setup a legal framework</li>
              <li>
                Buying and selling company shares or assets in Dubai and across
                the UAE
              </li>
            </ul>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} sm={12} className={classes.mainDiv}>
          <Typography
            variant="h3"
            className={classes.title}
            style={{ paddingBottom: "3%" }}
          >
            {"Translation Services"}
          </Typography>
          <Typography variant="h3" className={classes.subtitle2}>
            {
              "We translate documents from any language to Arabic and verify translations. Our translators work swiftly and efficiently to draft the best documents without errors. "
            }
          </Typography>
        </Grid>
      </Grid>

      <Grid container justifyContent="center" className={classes.detailDiv}>
        {!isMobile && (
          <Grid item xs={4} md={2} sm={4} className={classes.div1}>
            {"We help with"}
          </Grid>
        )}
        <Grid item xs={12} md={10} sm={8} className={classes.div2}>
          {" "}
          {isMobile && (
            <Typography
              style={{
                fontSize: "22px",
                color: "#C01718",
                fontFamily: "Almarai-SemiBold",
                textAlign: "center",
              }}
            >
              {"We help with"}
            </Typography>
          )}
          <Typography variant="h5" className={classes.titleDiv}>
            <span className={classes.num}> 01</span>
            {"Book an in-person or remote consultation to prepare a draft"}
          </Typography>
          <Typography variant="h5" className={classes.subDiv}>
            {
              "We’ll discuss your notary needs over the phone or at your home/office and start preparing a draft of the required documents."
            }
          </Typography>
          <Typography variant="h5" className={classes.titleDiv}>
            {" "}
            <span className={classes.num}> 02</span> {"Identity Verification"}
          </Typography>
          <Typography variant="h5" className={classes.subDiv}>
            {
              "Once the draft is done, make sure to keep it ready along with a valid Emirates ID and passport for verification. Our notaries will then video call to verify your identity"
            }
          </Typography>
          <Typography variant="h5" className={classes.titleDiv}>
            {" "}
            <span className={classes.num}> 03</span> {"Easy online delivery"}
          </Typography>
          <Typography variant="h5" className={classes.subDiv}>
            {
              "You’ll receive an electronic signature from Dubai Courts through an OTP via SMS. Once this is received, the final documents will be delivered to your email or WhatsApp."
            }
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
