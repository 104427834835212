import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import AboutUs from "../../AboutUs/AboutUs";
import Carousel from "../../Carousel/Carousel";
import Faq from "../../Faq/Faq";
import HowItWorks from "../../HowItWorks/HowItWorks";
import ContactUs from "../../contactus/contactUs";
import WhatWe from "../../WhatWeDo/WhatDo";

import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Almarai-Light",
    textAlign: "center",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      fontSize: "32px !important",
    },
  },
  title2: {
    fontFamily: "Almarai-Light",
    textAlign: "center",
    lineHeight: "24.8px!important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px !important",
    },
  },
  title3: {
    fontFamily: "Almarai-SemiBold !important",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px !important",
    },
  },
  star: {
    position: "absolute",
    left: "103%",
    top: "-9%",
  },
  closebtn: {
    width: "32px",
    height: "32px",
    marginLeft: "91%  !important",
  },
  circleimg: {
    marginLeft: "-27%",
    marginBottom: "-3%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "-33%",
    },
  },
  circleimg2: {
    marginLeft: "-43%",
    marginBottom: "-3%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "-54%",
    },
  },
  chain: {
    textAlign: "center",
    padding: "4% 0%",
  },
  btn: {
    margin: "0% 1%!important",
  },
  btn2: {
    margin: "0% 1%!important",
    backgroundColor: "#a5e6a5 !important",
  },
  btn3: {
    fontFamily: "Almarai-Bold!important",
    height: "51px !important",
    fontSize: "14px !important",
    whiteSpace: "nowrap !important",
    color: "#FFFFFF!important",
    border: "1px solid white!important",
    margin: "12px 0px!important",
    borderRadius: "30px!important",
    width: "100%",
  },
}));

const Home = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <>
      <Carousel />
      <AboutUs />
      <WhatWe/>
      <HowItWorks />      
     <Faq />
      <ContactUs />
     
    </>
  );
};
export default Home;
