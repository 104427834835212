import React from "react";
import banner1 from "../../../assets/Notary Public Services.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Grid, IconButton, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  subtitle1: {
    fontSize: "26px!important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px!important",
      marginBottom: "3%!important",
    },
  },
  subtitle3: {
    fontSize: "20px !important",
    fontFamily: "Almarai-regular!important",
    marginBottom: "2%!important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px !important",
      textAlign: "center",
    },
  },

  mainDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column!important",
    padding: "4% 22%",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "10% 10% 4%",
      alignItems: "center",
    },
  },
  div1: {
    borderRight: "2px solid #C01718 !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    fontSize: "22px",
    color: "#C01718",
    fontFamily: "Almarai-SemiBold",
    padding: "0% 5% 0%",
  },
  div2: {
    padding: "0% 5% 0%",
  },
  borderDiv: {
    padding: "4% 24%",
  },
  border: {
    border: "2px solid #C01718",
    [theme.breakpoints.down("sm")]: {
      border: "2px solid #C01718",
    },
  },
  detailDiv: {
    padding: "0% 22% 16% 22%",
    [theme.breakpoints.down("sm")]: {
      padding: "0% 10% ",
    },
  },
  title: {
    fontSize: "32px!important",
    fontFamily: "Almarai-Bold!important",
    paddingBottom: "3%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "26px!important",
      paddingBottom: "7%",
    },
  },
  arrowBtn: {
    position: "absolute!important",
    color: "white!important",
    top: "15%",
    left: "3%",
    fontSize: "16px!important",
    [theme.breakpoints.down("sm")]: {
      left: "0%",
      top: "7%!important",
    },
  },
}));
export default function NotaryPublic() {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <div>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={12}>
          {" "}
          <IconButton href="/" className={classes.arrowBtn}>
            <ArrowBackIcon />
            {!isMobile && "Back"}
          </IconButton>
          <img src={banner1} alt="" style={{ width: "100%" }} />
        </Grid>

        <Grid item xs={12} md={12} sm={12} className={classes.mainDiv}>
          <Typography variant="h1" className={classes.title}>
            {"Notary Public Services"}
          </Typography>
          <Typography variant="h3" className={classes.subtitle1}>
            {
              "Our expert’s advice, draft and attest documents and paperwork relating to a variety of business activities"
            }
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" className={classes.detailDiv}>
        {!isMobile && (
          <Grid item xs={4} md={4} sm={4} className={classes.div1}>
            {"We help with"}
          </Grid>
        )}
        <Grid item xs={12} md={8} sm={8} className={classes.div2}>
          {isMobile && (
            <Typography
              style={{
                fontSize: "22px",
                color: "#C01718",
                fontFamily: "Almarai-SemiBold",
                textAlign: "center",
              }}
            >
              {"We help with"}
            </Typography>
          )}
          <ul className={classes.subtitle3}>
            <li> General / Special Power of Attorney</li>
            <li>
              Memorandum of Association and Selling Share Agreements <br />
              (only for licenses issued by Dubai Economic Department)
            </li>
            <li> Legal Notices and Endorsements</li>
            <li> Minutes of Meeting and Board Resolutions</li>
            <li> Legal Documents</li>
            <li>
              Notarizing memorandums, oaths, certificates, acknowledgements for
              a variety of business activities
            </li>
          </ul>
        </Grid>
      </Grid>
    </div>
  );
}
