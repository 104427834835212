import React, { useRef, useState } from "react";
import {
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/Add";
import { makeStyles } from "@mui/styles";
import Slider from "react-slick";
import Expand from "react-expand-animated";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: "center",  fontSize: "20px !important",paddingRight:'2%',
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px !important",
      marginBottom: "8%!important",
    },
  },
  maintitle:{  textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "22px !important",
      marginBottom: "2%!important",
    },
  },
  qustion: {
    width: "100%",
    fontSize: "18px!important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px !important",
    },
  },
  main: {
    padding: "3% 10%",
    [theme.breakpoints.down("sm")]: {
      padding: "8% 3%!important",
    },
  },
  btn: {
    fontSize: "28px!important",
    padding: "3px 30px !important",
    fontFamily: "Almarai-SemiBold !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "22px !important",
    },
  },
  submain: {
    paddingBottom: "3%",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },
  border: {
    border: "4px solid #C01718",
    [theme.breakpoints.down("sm")]: {
      border: "4px solid #C01718",
    },
  },
  borderDiv: {
    padding: "0.5% 46% 3%",
    [theme.breakpoints.down("sm")]: {
      padding: "0% 42% 4%",
    },
  },
}));

export default function Faq() {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  function Arrow(props) {
    return (
      <span
        onClick={() => setOpen(!open)}
        className={open ? "" : "arrow"}
        style={{ textAlign: "center" }}
      >
        <div>
          {open ? (
            <KeyboardArrowUpIcon
              style={{ fontSize: "46px", color: "#C01718" }}
            />
          ) : (
            <KeyboardArrowDownIcon
              style={{ fontSize: "46px", marginTop: "2%", color: "#C01718" }}
            />
          )}
        </div>
      </span>
    );
  }
  return (
    <div id="faq" className={classes.main}>
      <Typography
        variant="h3"
        className={classes.maintitle}
        style={{ fontFamily: "Almarai-SemiBold", position: "relative" }}
      >
        {"Frequently Asked Questions"}
        <div className={classes.borderDiv}>
          <p className={classes.border}></p>
        </div>
      </Typography>
      <div style={{ position: "relative" }}>
        <Accordion>
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ color: "#292929" }} />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.qustion}>
              <span className={classes.title}>01</span>
              Do you notarize personal documents (passports, bank statements,
              mortgage, Know Your Customer, share certificates,
              education/marriage/birth certificates)?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.qustion}>
              No. Attestation is available for only specific documents. We don’t
              notarize or attest personal documents.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ color: "#292929" }} />
            }
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.qustion}>
              <span className={classes.title}> 02</span>
              Do you notarize Memorandum of Understanding or Settlement
              Agreements?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.qustion}>
              No. Notarization is available for only specific corporate
              documents. Speak to one of our consultants to learn which
              documents we can help you notarize.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ color: "#292929" }} />
            }
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.qustion}>
              {" "}
              <span className={classes.title}> 03</span>
              Can I grant power of attorney to someone in Dubai / UAE if I’m not
              in the UAE?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.qustion}>
              Yes, we can arrange attestation online over a video call. You will
              then receive the notarized documents on your email. Easy and
              quick.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ color: "#292929" }} />
            }
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.qustion}>
              <span className={classes.title}> 04</span>
              Can I grant power of attorney or use notary services in the UAE
              without an Emirates ID?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.qustion}>
              Yes you can as long as you have your original passport.
            </Typography>
          </AccordionDetails>
        </Accordion>{" "}
        <Accordion>
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon sx={{ color: "#292929" }} />
            }
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.qustion}>
              <span className={classes.title}> 05</span>
              Will I need to hire a lawyer separately to draft Power of Attorney
              or Memorandum of Agreement?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.qustion}>
              No. Our legal experts can draft all required paperwork and provide
              advice on legal structure. You can also give us your own draft.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Expand open={open}>
          <Accordion>
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon sx={{ color: "#292929" }} />
              }
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={classes.qustion}>
                <span className={classes.title}> 06</span>
                Do I need to visit The Notary offices to notarize documents?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={classes.qustion}>
                No. Our convenient online and door-to-door services let you stay
                at home or office while we take care of document collection,
                attestation and delivery
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon sx={{ color: "#292929" }} />
              }
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={classes.qustion}>
                <span className={classes.title}> 07</span>How does remote
                notarization work?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={classes.qustion}>
                Once the document draft is ready, we arrange a video call for
                passport verification. The next step is obtaining an electronic
                signature, which is an OTP that you receive on your email or via
                SMS. After that, you’ll receive the final documents on your
                email or WhatsApp.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon sx={{ color: "#292929" }} />
              }
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={classes.qustion}>
                <span className={classes.title}> 08</span>How long does it take
                to notarize documents?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={classes.qustion}>
                Our notaries can complete the notarization process between 15-20
                minutes. This includes witnessing, signing and stamping of
                documents.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon sx={{ color: "#292929" }} />
              }
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={classes.qustion}>
                <span className={classes.title}> 09</span>
                Is it possible to cancel a document after it’s been attested?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={classes.qustion}>
                Yes. Cancellation can be carried out anytime by taking out a
                legal notice. We can help you draft the legal notice as well.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon sx={{ color: "#292929" }} />
              }
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={classes.qustion}>
                <span className={classes.title}> 10</span>
                Is it possible to cancel a document after it’s been attested?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={classes.qustion}>
                Yes. Cancellation can be carried out anytime by taking out a
                legal notice. We can help you draft the legal notice as well.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon sx={{ color: "#292929" }} />
              }
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={classes.qustion}>
                <span className={classes.title}> 11</span>Can I register my will
                in Dubai?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={classes.qustion}>
                Yes. You can now register wills in-person or virtually with the
                The Notary or DIFC Wills Service Centre.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon sx={{ color: "#292929" }} />
              }
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={classes.qustion}>
                <span className={classes.title}> 12</span>What are your working
                hours?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={classes.qustion}>
                Monday to Friday from 8am to 8pm
              </Typography>
            </AccordionDetails>
          </Accordion>{" "}
        </Expand>
        <Arrow />
      </div>
    </div>
  );
}
