import React from "react";
import backgroud from "../../assets/about_us/background.svg";
import balance from "../../assets/about_us/balance.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles } from "@mui/styles";
import { Grid, Button, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  closePaper: {
    borderRadius: "18px",
    padding: "20px",
  },
  title: {
    [theme.breakpoints.down("sm")]: { fontSize: "42px !important" },
  },
  qtitle: {
    fontFamily: "Almarai-SemiBold!important",
    fontSize: "26px !important",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: { fontSize: "20px !important" },
  },
  subtitle1: {
    fontSize: "46px!important",
    fontFamily: "Almarai-Bold!important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "26px!important",
      marginBottom:"3%!important"
    },
  },
  subtitle2: {
    fontSize: "32px!important",
    fontFamily: "Almarai-Bold!important",
    padding: "2% 0%",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "3%!important",
      fontSize: "18px!important",
    },
  },
  subtitle3: {
    fontSize: "20px !important",
    fontFamily: "Almarai-regular!important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px !important",
      textAlign: "center",
    },
  },
  mainDiv2: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    flexDirection: "column!important",
    [theme.breakpoints.down("sm")]: { alignItems: "center" },
  },
  mainDiv: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "flex-start",
    flexDirection: "column!important",
    padding: "0% 5%",
    [theme.breakpoints.down("sm")]: { padding: "1%", alignItems: "center" },
  },
  div1: {
    whiteSpace: "nowrap",
    paddingTop: "2%",
    [theme.breakpoints.down("sm")]: { padding: "10% 0%" },
  },

  mainDiv1: {
    padding: "6% 8%",
    [theme.breakpoints.down("sm")]: { padding: "5% 5%" },
  },
  btn: {
    [theme.breakpoints.down("sm")]: {
      padding: '7px 23px !important',
      margin:'3% !important'
    },
  },
  closebtn: {
    width: "32px",
    height: "32px",
    marginLeft: "91%  !important",
    position: "absolute  !important",
    zIndex: "1",
    right: "16px",
    top: "16px",
    backgroundColor: "#ffbb00!important",
    [theme.breakpoints.down("sm")]: {
      right: "30px",
      top: "30px",
    },
  },
  border: {
    border: "4px solid #C01718",
    width: "100px",
    marginTop: "3%",
    [theme.breakpoints.down("sm")]: {
      border: "2px solid #C01718",
      left: "43%",
      width: "55px",marginTop: "0%",
      position: "absolute",
    },
  },
}));
export default function AboutUs() {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <div style={{ background: `url('${backgroud}') ` }} id="about">
      <Grid container justifyContent="center" className={classes.mainDiv1}>
        {!isMobile && (
          <Grid item xs={12} sm={12} md={5} className={classes.mainDiv2}>
            <img src={balance} alt="" style={{ width: "70%" }} />
          </Grid>
        )}
        <Grid item xs={12} md={7} sm={12} className={classes.mainDiv}>
          <Typography variant="h3" className={classes.subtitle1}>
            {"About Us"}
            <p className={classes.border}></p>
          </Typography>

          <Typography className={classes.subtitle2}>
            {"  Notarize online. Anytime. Anywhere"}
          </Typography>
          {isMobile ? (
            <Typography className={classes.subtitle3}>
              Welcome to the leading Notary Public firm in the UAE. The Notary
              is licensed by Dubai Courts.
            </Typography>
          ) : (
            <>
              <Typography className={classes.subtitle3}>
                Welcome to the leading Notary Public firm in the UAE. The Notary
                is licensed by Dubai Courts.
              </Typography>
              <Typography className={classes.subtitle3}>
                Modern times need modern notary services. Our notarization,
                translation, and legalization services are designed to be
                flexible to fit your schedule and budget. With our streamlined
                online process specializing in electronic transactions and
                attestations, you can get your documents attested in a matter of
                minutes/hours.
              </Typography>
            </>
          )}
          <Button variant="contained" href='/aboutUs'className={classes.btn}>Read more</Button>
        </Grid>
        {isMobile && (
          <Grid item xs={12} sm={12} md={5} className={classes.mainDiv2}>
            <img src={balance} alt="" style={{ width: "100%" }} />
          </Grid>
        )}
      </Grid>
    </div>
  );
}
